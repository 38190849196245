<template>
    <div class="layout-analytics-dashboard">

        <div class="grid">

            <div class="col-12">
                <div class="widget-header flex flex-wrap align-items-center justify-content-between pt-3 pb-5">
                    <div class="header-left-section flex align-items-center justify-content-start mb-3 md:mb-0">
                        <div class="ml-3 mb-2 md:mb-0">
                        <span class="text-4xl line-height-3 font-light block">Relatórios Protheus (Arquivos)
                        </span>
                            <span class="block subtext">Clique em exportar e aguarde a montagem de seu arquivo</span>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-12 md:col-6 lg:col-6" >
                <div class="card widget-tasks">
                    <div class="card-header block mb-3 px-2">
                        <span class="block font-bold text-2xl mb-4">
                             <Avatar icon="pi pi-list" size="large" shape="circle" class="text-base font-bold"
                                     :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}">
                             </Avatar>
                            Análise
                            <span class="clock"> de Resultados (Novo)
                            </span>
                        </span>
                        <br />
                        <div class="p-fluid formgrid grid">

                            <div class="field col-4 md:col-4">
                                <label for="dtemissao">Período</label>
                                <Calendar :showIcon="true"
                                          :showButtonBar="true"
                                          v-model="dataIniRel7"
                                          placeholder="Selecione a Data Inicial"
                                          dateFormat="dd/mm/yy"
                                          view='month'
                                          :disabled='loadingRel1 || loadingRel2 || loadingRel3 || loadingRel4 || loadingRel5 || loadingRel6 || loadingRel7 || loadingRel11'
                                          style="width: 100%"></Calendar>
                            </div>

                            <div class="field col-4 md:col-4">
                                <label for="dtemissao">Data Final</label>
                                <Calendar :showIcon="true"
                                          :showButtonBar="true"
                                          v-model="dataFimRel7"
                                          placeholder="Selecione a Data Inicial"
                                          dateFormat="dd/mm/yy"
                                          :disabled='loadingRel1 || loadingRel2 || loadingRel3 || loadingRel4 || loadingRel5 || loadingRel6 || loadingRel7 || loadingRel11'
                                          style="width: 100%"></Calendar>
                            </div>

                            <div class="field col-4 md:col-4">
                                <label for="dtemissao">Código Vendedor</label>
                                <InputText id="codrepres"
                                           type="text"
                                           placeholder='Código Vendedor'
                                           :disabled="perfilUser !== 'admin'"
                                           v-model="codRepres"/>
                            </div>

                        </div>
                    </div>
                    <div class="card-footer flex flex-wrap align-items-center justify-content-between pt-3">
                        <div class="footer-left flex align-items-center">
                        </div>
                        <div class="footer-right">
                            <Button :loading='loadingRel7'
                                    type="button"
                                    icon="pi pi-download"
                                    :label="`${loadingRel7 === true ? 'Aguardando resposta Protheus...' : 'Exportar Análise de Resultados'}`"
                                    class="p-button-outlined"
                                    :disabled='loadingRel1 || loadingRel2 || loadingRel3 || loadingRel4 || loadingRel5 || loadingRel6 || loadingRel7 || loadingRel11'
                                    @click="baixarAnaliseResult()"/>

                        </div>
                    </div>
                </div>
            </div>

          <div class="col-12 md:col-6 lg:col-6" >
            <div class="card widget-tasks">
              <div class="card-header block mb-3 px-2">
                        <span class="block font-bold text-2xl mb-4">
                             <Avatar icon="pi pi-list" size="large" shape="circle" class="text-base font-bold"
                                     :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}">
                             </Avatar>
                            Análise
                            <span class="clock"> de Resultados 2 (Novo)
                            </span>
                        </span>
                <br />
                <div class="p-fluid formgrid grid">

                  <div class="field col-4 md:col-4">
                    <label for="dtemissao">Período</label>
                    <Calendar :showIcon="true"
                              :showButtonBar="true"
                              v-model="dataIniRel7"
                              placeholder="Selecione a Data Inicial"
                              dateFormat="dd/mm/yy"
                              view='month'
                              :disabled='loadingRel1 || loadingRel2 || loadingRel3 || loadingRel4 || loadingRel5 || loadingRel6 || loadingRel7 || loadingRel11'
                              style="width: 100%"></Calendar>
                  </div>

                  <div class="field col-4 md:col-4">
                    <label for="dtemissao">Data Final</label>
                    <Calendar :showIcon="true"
                              :showButtonBar="true"
                              v-model="dataFimRel7"
                              placeholder="Selecione a Data Inicial"
                              dateFormat="dd/mm/yy"
                              :disabled='loadingRel1 || loadingRel2 || loadingRel3 || loadingRel4 || loadingRel5 || loadingRel6 || loadingRel7 || loadingRel11'
                              style="width: 100%"></Calendar>
                  </div>

                  <div class="field col-4 md:col-4">
                    <label for="dtemissao">Código Vendedor</label>
                    <InputText id="codrepres"
                               type="text"
                               placeholder='Código Vendedor'
                               :disabled="perfilUser !== 'admin'"
                               v-model="codRepres"/>
                  </div>

                </div>
              </div>
              <div class="card-footer flex flex-wrap align-items-center justify-content-between pt-3">
                <div class="footer-left flex align-items-center">
                </div>
                <div class="footer-right">
                  <Button :loading='loadingRel7'
                          type="button"
                          icon="pi pi-download"
                          :label="`${loadingRel7 === true ? 'Aguardando resposta Protheus...' : 'Exportar Análise de Resultados'}`"
                          class="p-button-outlined"
                          :disabled='loadingRel1 || loadingRel2 || loadingRel3 || loadingRel4 || loadingRel5 || loadingRel6 || loadingRel7 || loadingRel11'
                          @click="baixarAnaliseResult2()"/>

                </div>
              </div>
            </div>
          </div>


            <div class="col-12 md:col-6 lg:col-6" >
                <div class="card widget-tasks">
                    <div class="card-header block mb-3 px-2">
                        <span class="block font-bold text-2xl mb-4">
                             <Avatar
                                 icon="pi pi-chart-bar" size="large" shape="circle" class="text-base font-bold"
                                 :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}">
                            </Avatar>
                            Metas
                            <span class="clock"> (Data)
                            </span>
                        </span>
                        <br />
                        <div class="p-fluid formgrid grid">
                            <div class="field col-12 md:col-6">
                                <label for="dtemissao">Emissão</label>
                                <Calendar :showIcon="true"
                                          :showButtonBar="true"
                                          v-model="dataIniRel1"
                                          placeholder="Selecione a Data Inicial"
                                          dateFormat="dd/mm/yy"
                                          :disabled='loadingRel1 || loadingRel2 || loadingRel3 || loadingRel4 || loadingRel5 || loadingRel6 || loadingRel7 || loadingRel11'
                                          style="width: 100%"></Calendar>
                            </div>
                            <div class="field col-12 md:col-6">
                                <label for="dtemissao">Código Vendedor</label>
                                <InputText id="codrepres"
                                           type="text"
                                           placeholder='Código Vendedor'
                                           :disabled="perfilUser !== 'admin'"
                                           v-model="codRepres"/>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer flex flex-wrap align-items-center justify-content-between pt-3">
                        <div class="footer-left flex align-items-center">
                        </div>
                        <div class="footer-right">
                            <Button :loading='loadingRel1'
                                    type="button"
                                    icon="pi pi-download"
                                    :label="`${loadingRel1 === true ? 'Aguardando resposta Protheus...' : 'Exportar Relatório Metas'}`"
                                    class="p-button-outlined"
                                    :disabled='loadingRel1 || loadingRel2 || loadingRel3 || loadingRel4 || loadingRel5 || loadingRel6 || loadingRel7 || loadingRel11'
                                    @click="baixarRelatMetas()"/>

                        </div>
                    </div>
                </div>
            </div>






            <div class="col-12 md:col-6 lg:col-6" >
                <div class="card widget-tasks">
                    <div class="card-header block mb-3 px-2">
                        <span class="block font-bold text-2xl mb-4">
                             <Avatar icon="pi pi-list" size="large" shape="circle" class="text-base font-bold"
                                 :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}">
                             </Avatar>
                            Relatório
                            <span class="clock"> Faturamento Geral
                            </span>
                        </span>
                        <br />
                        <div class="p-fluid formgrid grid">
                            <div class="field col-12 md:col-6">
                                <label for="dtemissao">Data Inicial</label>
                                <Calendar :showIcon="true"
                                          :showButtonBar="true"
                                          v-model="dataIniRel3"
                                          placeholder="Selecione a Data Inicial"
                                          dateFormat="dd/mm/yy"
                                          :disabled='loadingRel1 || loadingRel2 || loadingRel3 || loadingRel4 || loadingRel5 || loadingRel6 || loadingRel7 || loadingRel11'
                                          style="width: 100%"></Calendar>
                            </div>
                            <div class="field col-12 md:col-6">
                                <label for="dtemissao">Data Final</label>
                                <Calendar :showIcon="true"
                                          :showButtonBar="true"
                                          v-model="dataFimRel3"
                                          placeholder="Selecione a Data Inicial"
                                          dateFormat="dd/mm/yy"
                                          :disabled='loadingRel1 || loadingRel2 || loadingRel3 || loadingRel4 || loadingRel5 || loadingRel6 || loadingRel7 || loadingRel11'
                                          style="width: 100%"></Calendar>
                            </div>
                            <div class="field col-12 md:col-6">
                                <label for="dtemissao">Código Vendedor</label>
                                <InputText id="codrepres"
                                           type="text"
                                           placeholder='Código Vendedor'
                                           :disabled="perfilUser !== 'admin'"
                                           v-model="codRepres"/>
                            </div>
                            <div class="field col-12 md:col-12 mt-3">
                                <label for="optsrel4">Opções</label>
                                <SelectButton id='optsrel4'
                                              v-model="selecionadosOpsRel3" :options="opcoesRel3"
                                              optionLabel="name"
                                              :disabled='loadingRel1 || loadingRel2 || loadingRel3 || loadingRel4 || loadingRel5 || loadingRel6 || loadingRel7 || loadingRel11'
                                              :multiple="true"/>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer flex flex-wrap align-items-center justify-content-between pt-3">
                        <div class="footer-left flex align-items-center">
                        </div>
                        <div class="footer-right">
                            <Button :loading='loadingRel3'
                                    type="button"
                                    icon="pi pi-download"
                                    :label="`${loadingRel3 === true ? 'Aguardando resposta Protheus...' : 'Exportar Relatório NESR798'}`"
                                    class="p-button-outlined"
                                    :disabled='loadingRel1 || loadingRel2 || loadingRel3 || loadingRel4 || loadingRel5 || loadingRel6 || loadingRel7 || loadingRel11'
                                    @click="baixarRelatNESR798()"/>

                        </div>
                    </div>
                </div>
            </div>


            <div class="col-12 md:col-6 lg:col-6" >
                <div class="card widget-tasks">
                    <div class="card-header block mb-3 px-2">
                        <span class="block font-bold text-2xl mb-4">
                             <Avatar icon="pi pi-list" size="large" shape="circle" class="text-base font-bold"
                                     :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}">
                             </Avatar>
                            Relatório
                            <span class="clock"> Faturamento Mensal
                            </span>
                        </span>
                        <br />
                        <div class="p-fluid formgrid grid">
                            <div class="field col-12 md:col-6">
                                <label for="dtemissao">Data Inicial</label>
                                <Calendar :showIcon="true"
                                          :showButtonBar="true"
                                          v-model="dataIniRel4"
                                          placeholder="Selecione a Data Inicial"
                                          dateFormat="dd/mm/yy"
                                          :disabled='loadingRel1 || loadingRel2 || loadingRel3 || loadingRel4 || loadingRel5 || loadingRel6 || loadingRel7 || loadingRel11'
                                          style="width: 100%"></Calendar>
                            </div>
                            <div class="field col-12 md:col-6">
                                <label for="dtemissao">Data Final</label>
                                <Calendar :showIcon="true"
                                          :showButtonBar="true"
                                          v-model="dataFimRel4"
                                          placeholder="Selecione a Data Inicial"
                                          dateFormat="dd/mm/yy"
                                          :disabled='loadingRel1 || loadingRel2 || loadingRel3 || loadingRel4 || loadingRel5 || loadingRel6 || loadingRel7 || loadingRel11'
                                          style="width: 100%"></Calendar>
                            </div>
                            <div class="field col-12 md:col-6">
                                <label for="dtemissao">Código Vendedor</label>
                                <InputText id="codrepres"
                                           type="text"
                                           placeholder='Código Vendedor'
                                           :disabled="perfilUser !== 'admin'"
                                           v-model="codRepres"/>
                            </div>
                            <div class="field col-12 md:col-12 mt-3">
                                <label for="optsrel4">Opções</label>
                                <SelectButton id='optsrel4'
                                              v-model="selecionadosOpsRel4"
                                              :options="opcoesRel4"
                                              optionLabel="name"
                                              :disabled='loadingRel1 || loadingRel2 || loadingRel3 || loadingRel4 || loadingRel5 || loadingRel6 || loadingRel7 || loadingRel11'
                                              :multiple="true"/>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer flex flex-wrap align-items-center justify-content-between pt-3">
                        <div class="footer-left flex align-items-center">
                        </div>
                        <div class="footer-right">

                            <Button :loading='loadingRel4'
                                    type="button"
                                    icon="pi pi-download"
                                    :label="`${loadingRel4 === true ? 'Aguardando resposta Protheus...' : 'Exportar Relatório NESR799'}`"
                                    class="p-button-outlined"
                                    :disabled='loadingRel1 || loadingRel2 || loadingRel3 || loadingRel4 || loadingRel5 || loadingRel6 || loadingRel7 || loadingRel11'
                                    @click="baixarRelatNESR799()"/>

                        </div>
                    </div>
                </div>
            </div>



            <div class="col-12 md:col-6 lg:col-6" >
                <div class="card widget-tasks">
                    <div class="card-header block mb-3 px-2">
                        <span class="block font-bold text-2xl mb-4">
                             <Avatar icon="pi pi-chart-bar" size="large" shape="circle" class="text-base font-bold"
                                     :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}">
                             </Avatar>
                            Comissões
                            <span class="clock"> (Data)
                            </span>
                        </span>
                        <br />
                        <div class="p-fluid formgrid grid">
                            <div class="field col-12 md:col-6">
                                <label for="dtemissao">Data Inicial</label>
                                <Calendar :showIcon="true"
                                          :showButtonBar="true"
                                          v-model="dataIniRel5"
                                          placeholder="Selecione a Data Inicial"
                                          dateFormat="dd/mm/yy"
                                          :disabled='loadingRel1 || loadingRel2 || loadingRel3 || loadingRel4 || loadingRel5 || loadingRel6 || loadingRel7 || loadingRel11'
                                          style="width: 100%"></Calendar>
                            </div>
                            <div class="field col-12 md:col-6">
                                <label for="dtemissao">Data Final</label>
                                <Calendar :showIcon="true"
                                          :showButtonBar="true"
                                          v-model="dataFimRel5"
                                          placeholder="Selecione a Data Inicial"
                                          dateFormat="dd/mm/yy"
                                          :disabled='loadingRel1 || loadingRel2 || loadingRel3 || loadingRel4 || loadingRel5 || loadingRel6 || loadingRel7 || loadingRel11'
                                          style="width: 100%"></Calendar>
                            </div>
                            <div class="field col-12 md:col-6">
                                <label for="dtemissao">Código Vendedor</label>
                                <InputText id="codrepres"
                                           type="text"
                                           placeholder='Código Vendedor'
                                           :disabled="perfilUser !== 'admin'"
                                           v-model="codRepres"/>
                            </div>

                        </div>
                    </div>
                    <div class="card-footer flex flex-wrap align-items-center justify-content-between pt-3">
                        <div class="footer-left flex align-items-center">
                        </div>
                        <div class="footer-right">

                            <Button :loading='loadingRel5'
                                    type="button"
                                    icon="pi pi-download"
                                    :label="`${loadingRel5 === true ? 'Aguardando resposta Protheus...' : 'Exportar Relatório Comissões'}`"
                                    class="p-button-outlined"
                                    :disabled='loadingRel1 || loadingRel2 || loadingRel3 || loadingRel4 || loadingRel5 || loadingRel6 || loadingRel7 || loadingRel11'
                                    @click="baixarRelatComissoes()"/>

                        </div>
                    </div>
                </div>
            </div>



          <div class="col-12 md:col-6 lg:col-6" >
            <div class="card widget-tasks">
              <div class="card-header block mb-3 px-2">
                        <span class="block font-bold text-2xl mb-4">
                             <Avatar icon="pi pi-chart-bar" size="large" shape="circle" class="text-base font-bold"
                                     :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}">
                             </Avatar>
                            Pedidos em Aberto
                            <span class="clock"> (Data)
                            </span>
                        </span>
                <br />
                <div class="p-fluid formgrid grid">
                  <div class="field col-12 md:col-6">
                    <label for="dtemissao">Data Inicial</label>
                    <Calendar :showIcon="true"
                              :showButtonBar="true"
                              v-model="dataIniRel6"
                              placeholder="Selecione a Data Inicial"
                              dateFormat="dd/mm/yy"
                              :disabled='loadingRel1 || loadingRel2 || loadingRel3 || loadingRel4 || loadingRel5 || loadingRel6 || loadingRel7 || loadingRel11'
                              style="width: 100%"></Calendar>
                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="dtemissao">Data Final</label>
                    <Calendar :showIcon="true"
                              :showButtonBar="true"
                              v-model="dataFimRel6"
                              placeholder="Selecione a Data Inicial"
                              dateFormat="dd/mm/yy"
                              :disabled='loadingRel1 || loadingRel2 || loadingRel3 || loadingRel4 || loadingRel5 || loadingRel6 || loadingRel7 || loadingRel11'
                              style="width: 100%"></Calendar>
                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="dtemissao">Código Vendedor</label>
                    <InputText id="codrepres"
                               type="text"
                               placeholder='Código Vendedor'
                               :disabled="perfilUser !== 'admin'"
                               v-model="codRepres"/>
                  </div>

                </div>
              </div>
              <div class="card-footer flex flex-wrap align-items-center justify-content-between pt-3">
                <div class="footer-left flex align-items-center">
                </div>
                <div class="footer-right">

                  <Button :loading='loadingRel5'
                          type="button"
                          icon="pi pi-download"
                          :label="`${loadingRel5 === true ? 'Aguardando resposta Protheus...' : 'Exportar Relatório Pedidos em Aberto'}`"
                          class="p-button-outlined"
                          :disabled='loadingRel1 || loadingRel2 || loadingRel3 || loadingRel4 || loadingRel5 || loadingRel6 || loadingRel7 || loadingRel11'
                          @click="baixarRelatPedAberto()"/>

                </div>
              </div>
            </div>
          </div>


            <div class="col-12 md:col-6 lg:col-6" >
                <div class="card widget-tasks">
                    <div class="card-header block mb-3 px-2">
                        <span class="block font-bold text-2xl mb-4">
                             <Avatar
                                 icon="pi pi-money-bill" size="large" shape="circle" class="text-base font-bold"
                                 :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}">
                             </Avatar>
                            Inadimplência
                            <span class="clock"> (Vendedor)
                            </span>
                        </span>
                        <br />
                        <div class="p-fluid formgrid grid">
                            <div class="field col-12 md:col-6">
                                <label for="dtemissao">Código Vendedor</label>
                                <InputText id="codrepres"
                                           type="text"
                                           placeholder='Código Vendedor'
                                           :disabled="perfilUser !== 'admin'"
                                           v-model="codRepres"/>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer flex flex-wrap align-items-center justify-content-between pt-3">
                        <div class="footer-left flex align-items-center">
                        </div>
                        <div class="footer-right">
                            <Button :loading='loadingRel2'
                                    type="button"
                                    icon="pi pi-download"
                                    :label="`${loadingRel2 === true ? 'Aguardando resposta Protheus...' : 'Exportar Relatório Inadimplência'}`"
                                    class="p-button-outlined"
                                    :disabled='loadingRel1 || loadingRel2 || loadingRel3 || loadingRel4 || loadingRel5 || loadingRel6 || loadingRel7 || loadingRel11'
                                    @click="baixarRelatInadim()"/>
                        </div>
                    </div>
                </div>
            </div>



          <div class="col-12 md:col-6 lg:col-6" >
            <div class="card widget-tasks">
              <div class="card-header block mb-3 px-2">
                        <span class="block font-bold text-2xl mb-4">
                             <Avatar icon="pi pi-chart-bar" size="large" shape="circle" class="text-base font-bold"
                                     :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}">
                             </Avatar>
                            Região
                            <span class="clock"> (Data)
                            </span>
                        </span>
                <br />
                <div class="p-fluid formgrid grid">
                  <div class="field col-12 md:col-6">
                    <label for="dtemissao">Data Inicial</label>
                    <Calendar :showIcon="true"
                              :showButtonBar="true"
                              v-model="dataIniRel8"
                              placeholder="Selecione a Data Inicial"
                              dateFormat="dd/mm/yy"
                              :disabled='loadingRel1 || loadingRel2 || loadingRel3 || loadingRel4 || loadingRel5 || loadingRel6 || loadingRel7 || loadingRel7 || loadingRel8 || loadingRel11'
                              style="width: 100%"></Calendar>
                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="dtemissao">Data Final</label>
                    <Calendar :showIcon="true"
                              :showButtonBar="true"
                              v-model="dataFimRel8"
                              placeholder="Selecione a Data Inicial"
                              dateFormat="dd/mm/yy"
                              :disabled='loadingRel1 || loadingRel2 || loadingRel3 || loadingRel4 || loadingRel5 || loadingRel6 || loadingRel7 || loadingRel8 || loadingRel11'
                              style="width: 100%"></Calendar>
                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="dtemissao">Código Vendedor</label>
                    <InputText id="codrepres"
                               type="text"
                               placeholder='Código Vendedor'
                               :disabled="perfilUser !== 'admin'"
                               v-model="codRepres"/>
                  </div>

                </div>
              </div>
              <div class="card-footer flex flex-wrap align-items-center justify-content-between pt-3">
                <div class="footer-left flex align-items-center">
                </div>
                <div class="footer-right">

                  <Button :loading='loadingRel8'
                          type="button"
                          icon="pi pi-download"
                          :label="`${loadingRel8 === true ? 'Aguardando resposta Protheus...' : 'Exportar Relatório Pedidos em Aberto'}`"
                          class="p-button-outlined"
                          :disabled='loadingRel1 || loadingRel2 || loadingRel3 || loadingRel4 || loadingRel5 || loadingRel6 || loadingRel7 || loadingRel8 || loadingRel11'
                          @click="baixarRelatRegiao()"/>

                </div>
              </div>
            </div>
          </div>


          <div class="col-12 md:col-6 lg:col-6" >
            <div class="card widget-tasks">
              <div class="card-header block mb-3 px-2">
                        <span class="block font-bold text-2xl mb-4">
                             <Avatar icon="pi pi-chart-bar" size="large" shape="circle" class="text-base font-bold"
                                     :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}">
                             </Avatar>
                            Novos Clientes
                            <span class="clock"> (Data)
                            </span>
                        </span>
                <br />
                <div class="p-fluid formgrid grid">
                  <div class="field col-12 md:col-6">
                    <label for="dtemissao">Data Inicial</label>
                    <Calendar :showIcon="true"
                              :showButtonBar="true"
                              v-model="dataIniRel9"
                              placeholder="Selecione a Data Inicial"
                              dateFormat="dd/mm/yy"
                              :disabled='loadingRel1 || loadingRel2 || loadingRel3 || loadingRel4 || loadingRel5 || loadingRel6 || loadingRel7 || loadingRel7 || loadingRel8 || loadingRel9 || loadingRel11'
                              style="width: 100%"></Calendar>
                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="dtemissao">Data Final</label>
                    <Calendar :showIcon="true"
                              :showButtonBar="true"
                              v-model="dataFimRel9"
                              placeholder="Selecione a Data Inicial"
                              dateFormat="dd/mm/yy"
                              :disabled='loadingRel1 || loadingRel2 || loadingRel3 || loadingRel4 || loadingRel5 || loadingRel6 || loadingRel7 || loadingRel8 || loadingRel9 || loadingRel11'
                              style="width: 100%"></Calendar>
                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="dtemissao">Código Vendedor</label>
                    <InputText id="codrepres"
                               type="text"
                               placeholder='Código Vendedor'
                               :disabled="perfilUser !== 'admin'"
                               v-model="codRepres"/>
                  </div>

                </div>
              </div>
              <div class="card-footer flex flex-wrap align-items-center justify-content-between pt-3">
                <div class="footer-left flex align-items-center">
                </div>
                <div class="footer-right">

                  <Button :loading='loadingRel9'
                          type="button"
                          icon="pi pi-download"
                          :label="`${loadingRel9 === true ? 'Aguardando resposta Protheus...' : 'Exportar Relatório Pedidos em Aberto'}`"
                          class="p-button-outlined"
                          :disabled='loadingRel1 || loadingRel2 || loadingRel3 || loadingRel4 || loadingRel5 || loadingRel6 || loadingRel7 || loadingRel8 || loadingRel9 || loadingRel11'
                          @click="baixarRelatNovosCli()"/>

                </div>
              </div>
            </div>
          </div>



          <div class="col-12 md:col-6 lg:col-6" >
            <div class="card widget-tasks">
              <div class="card-header block mb-3 px-2">
                        <span class="block font-bold text-2xl mb-4">
                             <Avatar icon="pi pi-chart-bar" size="large" shape="circle" class="text-base font-bold"
                                     :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}">
                             </Avatar>
                            Curva ABC
                            <span class="clock"> (Data)
                            </span>
                        </span>
                <br />
                <div class="p-fluid formgrid grid">
                  <div class="field col-12 md:col-6">
                    <label for="dtemissao">Data Inicial</label>
                    <Calendar :showIcon="true"
                              :showButtonBar="true"
                              v-model="dataIniRel10"
                              placeholder="Selecione a Data Inicial"
                              dateFormat="dd/mm/yy"
                              :disabled='loadingRel1 || loadingRel2 || loadingRel3 || loadingRel4 || loadingRel5 || loadingRel6 || loadingRel7 || loadingRel7 || loadingRel8 || loadingRel9 || loadingRel10 || loadingRel11'
                              style="width: 100%"></Calendar>
                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="dtemissao">Data Final</label>
                    <Calendar :showIcon="true"
                              :showButtonBar="true"
                              v-model="dataFimRel10"
                              placeholder="Selecione a Data Inicial"
                              dateFormat="dd/mm/yy"
                              :disabled='loadingRel1 || loadingRel2 || loadingRel3 || loadingRel4 || loadingRel5 || loadingRel6 || loadingRel7 || loadingRel8 || loadingRel9 || loadingRel10 || loadingRel11'
                              style="width: 100%"></Calendar>
                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="dtemissao">Código Vendedor</label>
                    <InputText id="codrepres"
                               type="text"
                               placeholder='Código Vendedor'
                               :disabled="perfilUser !== 'admin'"
                               v-model="codRepres"/>
                  </div>

                </div>
              </div>
              <div class="card-footer flex flex-wrap align-items-center justify-content-between pt-3">
                <div class="footer-left flex align-items-center">
                </div>
                <div class="footer-right">

                  <Button :loading='loadingRel10'
                          type="button"
                          icon="pi pi-download"
                          :label="`${loadingRel10 === true ? 'Aguardando resposta Protheus...' : 'Exportar Relatório Pedidos em Aberto'}`"
                          class="p-button-outlined"
                          :disabled='loadingRel1 || loadingRel2 || loadingRel3 || loadingRel4 || loadingRel5 || loadingRel6 || loadingRel7 || loadingRel8 || loadingRel9 || loadingRel10 || loadingRel11'
                          @click="baixarRelatCurvaAbc()"/>

                </div>
              </div>
            </div>
          </div>


        </div>
    </div>

</template>

<script>
import PedidoService from '@/service/PedidoService';
import {FilterMatchMode, FilterOperator} from "primevue/api";
import moment from 'moment'


export default {
    name: 'Free',
    components: { },
    data() {
        return {
            perfilUser: '',

            loadingRel1: false,
            loadingRel2: false,
            loadingRel3: false,
            loadingRel4: false,
            loadingRel5: false,
            loadingRel6: false,
            loadingRel7: false,
            loadingRel8: false,
            loadingRel9: false,
            loadingRel10: false,
            loadingRel11: false,

            dataIniRel1: null,
            dataIniRel2: null,
            dataIniRel3: null,
            dataFimRel3: null,
            dataIniRel4: null,
            dataFimRel4: null,
            dataIniRel5: null,
            dataFimRel5: null,
            dataIniRel6: null,
            dataFimRel6: null,
            dataIniRel7: null,
            dataFimRel7: null,

            dataIniRel8: null,
            dataFimRel8: null,
            dataIniRel9: null,
            dataFimRel9: null,
            dataIniRel10: null,
            dataFimRel10: null,

            dataIniRel11: null,
            dataFimRel11: null,


            opcoesRel3: [
                {name: 'I.A.P.I', code: 'IAPI'},
            ],
            selecionadosOpsRel3:  [
                {name: 'I.A.P.I', code: 'IAPI'},
            ],
            opcoesRel4: [
                {name: 'Exibir Cliente', code: 'EXCLI'},
                {name: 'Totalizar', code: 'TOT'},
                {name: 'I.A.P.I', code: 'IAPI'},
            ],
            selecionadosOpsRel4:  [
                {name: 'Exibir Cliente', code: 'EXCLI'},
                {name: 'Totalizar', code: 'TOT'},
                {name: 'I.A.P.I', code: 'IAPI'},
            ],

            codRepres: '',
            json_export_fields: {
                "ID": "id",
                "CODIGO": "codexterno",
                "CRIADO":{
                    field: 'created_at',
                    callback: (value) => {
                        return `${this.formatDateTime(value)}`;
                    }
                },
                "EMISSAO":{
                    field: 'dtemissao',
                    callback: (value) => {
                        return `${this.formatDate(value)}`;
                    }
                },
                "CLIENTE": "cliente",
                "NOME": "nome",
                "CIDADE": "cidade",
                "ESTADO": "estado",
                "VALOR":{
                    field: 'valor',
                    callback: (value) => {
                        return `${this.formatDecimal(value)}`;
                    }
                },
                "PESO":{
                    field: 'peso',
                    callback: (value) => {
                        return `${this.formatDecimal(value)}`;
                    }
                },
                "STATUS": "status",
                "REPRESENTANTE": "repres",
                "USUARIO": "created_by",
            },

            valorPedidos: 0,
            valorPedidosMes: 0,

            pesoPedidos:0,
            pesoPedidosMes: 0,

            pedidos: [],
            pessoaData: [],

            selectedAutoValueCliente: null,
            autoFilteredCliente: [],
            autoValueCliente: [],

            representantes: null,
            selectedPedidos: null,
            loading1: false,
            filters1: null,
        }
    },
    pedidoService: null,
    created() {
        this.pedidoService = new PedidoService();
        this.initFilters();

    },
    mounted() {



        this.pedidoService.getssuserlog().then((data) => {
            console.log('metasData mounted')
            console.log(data)

            if (data.codrepres){
                this.codRepres = data.codrepres
            }
            this.perfilUser = data.tipo

            const t = new Date()

            this.dataIniRel1 = new Date(t.getFullYear(), t.getMonth(), 1)
            this.dataIniRel2 = new Date(t.getFullYear(), t.getMonth(), 1)

            this.dataIniRel3 = new Date(t.getFullYear(), t.getMonth(), 1)
            this.dataFimRel3 = new Date(t.getFullYear(), t.getMonth() + 1, 0, 23, 59, 59)

            this.dataIniRel4 = new Date(t.getFullYear(), t.getMonth(), 1)
            this.dataFimRel4 = new Date(t.getFullYear(), t.getMonth() + 1, 0, 23, 59, 59)


            this.dataIniRel5 = new Date(t.getFullYear(), t.getMonth(), 1);
            this.dataFimRel5 = new Date(t.getFullYear(), t.getMonth() + 1, 0, 23, 59, 59);


            this.dataIniRel6 = new Date(t.getFullYear(), t.getMonth(), 1);
            this.dataFimRel6 = new Date(t.getFullYear(), t.getMonth() + 1, 0, 23, 59, 59);

            this.dataIniRel7 = new Date(t.getFullYear(), t.getMonth(), 1)
            this.dataFimRel7 = new Date(t.getFullYear(), t.getMonth() + 1, 0, 23, 59, 59)

            this.dataIniRel8 = new Date(t.getFullYear(), t.getMonth(), 1)
            this.dataFimRel8 = new Date(t.getFullYear(), t.getMonth() + 1, 0, 23, 59, 59)

            this.dataIniRel9 = new Date(t.getFullYear(), t.getMonth(), 1)
            this.dataFimRel9 = new Date(t.getFullYear(), t.getMonth() + 1, 0, 23, 59, 59)

            this.dataIniRel10 = new Date(t.getFullYear(), t.getMonth(), 1)
            this.dataFimRel10 = new Date(t.getFullYear(), t.getMonth() + 1, 0, 23, 59, 59)

            this.dataIniRel11 = new Date(t.getFullYear(), t.getMonth(), 1)
            this.dataFimRel11 = new Date(t.getFullYear(), t.getMonth() + 1, 0, 23, 59, 59)

            // this.loading1 = false
            // this.representantes = data
        })

    },

    methods: {



        baixarAnaliseResult(){
            console.log('baixarAnaliseResult_0')

            const format = "YYYY-MM-DD"


            let dateI = moment(this.dataIniRel7).format(format);
            let dateF = moment(this.dataFimRel7).format(format);

            console.log(dateI)
            console.log(dateF)

            this.loadingRel7 = true
            this.pedidoService.downloadAnalResult({
              'datai': dateI,
              'dataf': dateF,
              'repres': this.codRepres,
            }).then(() => {
              this.loadingRel7 = false
            }).catch((error) => {
              console.log('erro response protheus')
              console.log(error)

              this.loadingRel7 = false
              alert(`Tempo limite de execução excedido, favor tentar novamente em alguns instantes`)


            })




        },


        baixarAnaliseResult2(){
            console.log('baixarAnaliseResult2')

            const format = "YYYY-MM-DD"

            let dateI = moment(this.dataIniRel11).format(format);
            let dateF = moment(this.dataFimRel11).format(format);

            this.loadingRel11 = true
            this.pedidoService.downloadAnalResult2({
                    'datai': dateI,
                    'dataf': dateF,
                    'repres': this.codRepres,
                }).then(() => {
                this.loadingRel11 = false
            })

        },


        baixarRelatComissoes(){
            console.log('baixarRelatComissoes')
            this.loadingRel5 = true
            this.pedidoService.downloadRelatComiss({
                'datai': this.dataIniRel5,
                'dataf': this.dataFimRel5,
                'repres': this.codRepres
            }).then((response) => {
                console.log('downloadPdf response');
                // console.log(response)
                // console.log('base64ToXml')
                // console.log(response)
                var b64 = response
                var link = document.createElement('a')
                link.innerHTML = 'Download XML Nota Fiscal'
                link.download = `relatorio_comissoes.xls`
                link.href = 'data:application/octet-stream;base64,' + b64;
                link.click()
                this.loadingRel5 = false
            }).catch((error) => {
                console.log('erro response protheus')
                console.log(error)
                this.$toast.add({severity:'error', summary: 'Erro Protheus', detail: error.messages, life: 3000});
                this.loadingRel5 = false

            })

        },

      baixarRelatPedAberto(){
            console.log('baixarRelatPedAberto')
            this.loadingRel5 = true
            this.pedidoService.downloadRelatPedAberto({
                'datai': this.dataIniRel6,
                'dataf': this.dataFimRel6,
                'repres': this.codRepres
            }).then((response) => {
                console.log('downloadPdf response');
                // console.log(response)
                // console.log('base64ToXml')
                // console.log(response)
                var b64 = response
                var link = document.createElement('a')
                link.innerHTML = 'Download XML Nota Fiscal'
                link.download = `relatorio_pedidos_aberto.xls`
                link.href = 'data:application/octet-stream;base64,' + b64;
                link.click()
                this.loadingRel5 = false
            }).catch((error) => {
                console.log('erro response protheus')
                console.log(error)
                this.$toast.add({severity:'error', summary: 'Erro Protheus', detail: error.messages, life: 3000});
                this.loadingRel5 = false

            })

        },


      baixarRelatRegiao(){
            console.log('baixarRelatRegiao')
            this.loadingRel5 = true
            this.pedidoService.downloadRelatRegiao({
                'datai': this.dataIniRel8,
                'dataf': this.dataFimRel8,
                'repres': this.codRepres
            }).then((response) => {
                console.log('downloadPdf response');
                // console.log(response)
                // console.log('base64ToXml')
                // console.log(response)
                var b64 = response
                var link = document.createElement('a')
                link.innerHTML = 'Download XML Nota Fiscal'
                link.download = `relatorio_regiao.xls`
                link.href = 'data:application/octet-stream;base64,' + b64;
                link.click()
                this.loadingRel5 = false
            }).catch((error) => {
                console.log('erro response protheus')
                console.log(error)
                this.$toast.add({severity:'error', summary: 'Erro Protheus', detail: error.messages, life: 3000});
                this.loadingRel5 = false

            })

        },

      baixarRelatNovosCli(){
        console.log('baixarRelatNovosCli')
        this.loadingRel5 = true
        this.pedidoService.downloadRelatNovosCli({
          'datai': this.dataIniRel9,
          'dataf': this.dataFimRel9,
          'repres': this.codRepres
        }).then((response) => {
          console.log('downloadPdf response');
          // console.log(response)
          // console.log('base64ToXml')
          // console.log(response)
          var b64 = response
          var link = document.createElement('a')
          link.innerHTML = 'Download XML Nota Fiscal'
          link.download = `relatorio_novos_cli.xls`
          link.href = 'data:application/octet-stream;base64,' + b64;
          link.click()
          this.loadingRel5 = false
        }).catch((error) => {
          console.log('erro response protheus')
          console.log(error)
          this.$toast.add({severity:'error', summary: 'Erro Protheus', detail: error.messages, life: 3000});
          this.loadingRel5 = false

        })

      },

      baixarRelatCurvaAbc(){
        console.log('baixarRelatNovosCli')
        this.loadingRel5 = true
        this.pedidoService.baixarRelatCurvaAbc({
          'datai': this.dataIniRel9,
          'dataf': this.dataFimRel9,
          'repres': this.codRepres
        }).then((response) => {
          console.log('downloadPdf response');
          // console.log(response)
          // console.log('base64ToXml')
          // console.log(response)
          var b64 = response
          var link = document.createElement('a')
          link.innerHTML = 'Download XML Nota Fiscal'
          link.download = `relatorio_curva_abc.xls`
          link.href = 'data:application/octet-stream;base64,' + b64;
          link.click()
          this.loadingRel5 = false
        }).catch((error) => {
          console.log('erro response protheus')
          console.log(error)
          this.$toast.add({severity:'error', summary: 'Erro Protheus', detail: error.messages, life: 3000});
          this.loadingRel5 = false

        })

      },


        baixarRelatMetas(){
            console.log('baixarRelatMetas')
            this.loadingRel1 = true
            this.pedidoService.downloadRelatMeta({
                'data': this.dataIniRel1,
                'repres': this.codRepres
            }).then((response) => {
                console.log('downloadPdfNota response');
                // console.log(response)
                // console.log('base64ToXml')
                // console.log(response)
                var b64 = response
                var link = document.createElement('a')
                link.innerHTML = 'Download XML Nota Fiscal'
                link.download = `relatorio_metas.xls`
                link.href = 'data:application/octet-stream;base64,' + b64;
                link.click()
                this.loadingRel1 = false
            }).catch((error) => {
                console.log('erro response protheus')
                console.log(error)
                this.$toast.add({severity:'error', summary: 'Erro Protheus', detail: error.messages, life: 3000});
                this.loadingRel1 = false

            })

        },

        baixarRelatInadim(){
            console.log('baixarRelatInadim')
            this.loadingRel2 = true
            this.pedidoService.downloadRelatInadim({
                'repres': this.codRepres
            }).then((response) => {
                console.log('downloadPdfNota response');
                // console.log(response)
                // console.log('base64ToXml')
                // console.log(response)
                var b64 = response
                var link = document.createElement('a')
                link.innerHTML = 'Download XML Nota Fiscal'
                link.download = `relatorio_inadimplencia.xls`
                link.href = 'data:application/octet-stream;base64,' + b64;
                link.click()
                this.loadingRel2 = false
            });
        },

        baixarRelatNESR798(){
            console.log('baixarRelatNESR798')
            console.log(this.selecionadosOpsRel3)
            this.loadingRel3 = true
            this.pedidoService.downloadRelat798({
                'datai': this.dataIniRel3,
                'dataf': this.dataFimRel3,
                'repres': this.codRepres,
                'opcoes': this.selecionadosOpsRel3
            }).then((response) => {
                console.log('downloadPdfNota response');
                // console.log(response)
                // console.log('base64ToXml')
                // console.log(response)
                var b64 = response
                var link = document.createElement('a')
                link.innerHTML = 'Download XML Nota Fiscal'
                link.download = `relatorio_nesr798.xls`
                link.href = 'data:application/octet-stream;base64,' + b64;
                link.click()
                this.loadingRel3 = false
            });
        },

        baixarRelatNESR799(){
            console.log('baixarRelatNESR799')
            console.log(this.selecionadosOpsRel4)
            this.loadingRel3 = true
            this.pedidoService.downloadRelat799({
                'datai': this.dataIniRel4,
                'dataf': this.dataFimRel4,
                'repres': this.codRepres,
                'opcoes': this.selecionadosOpsRel4
            }).then((response) => {
                console.log('downloadPdfNota response');
                // console.log(response)
                // console.log('base64ToXml')
                // console.log(response)
                var b64 = response
                var link = document.createElement('a')
                link.innerHTML = 'Download XML Nota Fiscal'
                link.download = `relatorio_nesr799.xls`
                link.href = 'data:application/octet-stream;base64,' + b64;
                link.click()
                this.loadingRel3 = false
            });
        },


        searchCliente(event){
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.autoFilteredCliente = [...this.autoValueCliente];
                }
                else {
                    // console.log('vai pesquisar')
                    if (event.query.length >= 3){
                        this.pedidoService.getPesqCliente(event.query).then((data) => {
                            // console.log('getPesqCliente')
                            // console.log(data)
                            this.autoValueCliente = data
                            this.autoFilteredCliente = this.autoValueCliente.filter((pessoa) => {
                                if (pessoa.nome && event.query){
                                    // console.log(pessoa.nome)
                                    return pessoa.nome;
                                }
                            });
                        });
                    }
                }
            }, 250);
        },

        formatDateTime(value) {
            // console.log('value formatDate')
            // console.log(value)
            // console.log(moment())
            const format = "DD/MM/YYYY HH:mm:ss"
            var date = new Date(value);
            let dateTime = moment(date).format(format);
            // console.log(dateTime1)
            return dateTime
        },

        formatDate(value) {
            return value.toLocaleDateString('pt-BR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        },

        formatDecimal(value) {
            if (value)
                return value.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2});
            return;
        },
        formatCurrency(value) {
            if (value)
                return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
            return;
        },

        initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }

            this.filters1 = {
                'code': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},


                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'country.name': {
                    operator: FilterOperator.AND,
                    constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
                },
                'representative': {value: null, matchMode: FilterMatchMode.IN},
                'category': {value: null, matchMode: FilterMatchMode.IN},
                'date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
                'price': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'status': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'activity': {value: null, matchMode: FilterMatchMode.BETWEEN},
                'verified': {value: null, matchMode: FilterMatchMode.EQUALS}
            }

        },


        clearFilter1() {
            this.initFilters();
        },
    }
}
</script>
<style scoped lang="scss">
@import '../../assets/demo/styles/badges.scss';
</style>
